import React from 'react';
import { useHistory } from 'react-router-dom';
import Logo from '../../assets/logo2.png';

export default function Footer() {
	const history = useHistory();

	const login = () => history.push('/login');
	const contact = () => history.push('/contact');
	const privacy = () => history.push('/privacy');
	const terms = () => history.push('/terms');
	return (
		<footer className="footer">
            <div className="container">
            <div className="column is-half is-offset-one-quarter is-hidden-mobile">
					<img className="myimage" src={Logo} alt=""/>
					</div>
                <div className="columns">
                    
                    <div className="column is-4 has-text-centered is-hidden-tablet">
                        <img className="myimage" src={Logo} alt=""/>
					</div>
					<div className="column is-4 ">
						<div className="level">
							<a className="level-item" href={() => false} onClick={login}>
								Login
							</a>
							<a className="level-item" href={() => false} onClick={contact}>
								Contact Us
							</a>
						</div>
                    </div>
                    <div className="column is-4 has-text-centered is-hidden-mobile">
                    {/* <img className="image is-centered" src={Logo} alt=""/> */}
					</div>
					<div className="column is-4 has-text-right">
						<div className="level">
							<a className="level-item" href={() => false} onClick={terms}>
								Terms & Conditions
							</a>
							<a className="level-item" href={() => false} onClick={privacy}>
								Privacy Policies
							</a>
						</div>
					</div>
				</div>
				<p className="subtitle has-text-centered has-text-white is-6">
					© 2020 DiscGuard. All rights reserved.
				</p>
			</div>
		</footer>
	);
}
