import React from 'react'
import ServerListItem from './ServerListItem'
import { getGuilds, getUserDetails } from '../utils/api';
import { notify } from '../utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function ServerList({
    history,
}) {

    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [guilds, setGuilds] = React.useState({});

    React.useEffect(() => {
        getUserDetails()
            .then(({ data }) => {
                setUser(data);
                return getGuilds()
            }).then(({ data }) => {
                setGuilds(data)
                setLoading(false);
            }).catch((err) => {
                console.log(err)
                history.push('/')
                notify(toast, 'error', 'You need to be connected !')
                setLoading(false);
            })
        
    }, [])

    return !loading && (<>
        <section className="section is-medium is-fullheight-with-navbar">
        <h2 className="title has-text-centered has-text-white mb-6">My Servers</h2>   
                    <ServerListItem guilds={guilds} />
        </section>
        </>
    )
}