import React from 'react';
 import { useHistory } from 'react-router-dom';
import AuthOptions from '../auth/AuthOptions';
import Panel from '../auth/Panel';
import Logo from '../../assets/logo.png';

export default function Header() {
	 const history = useHistory();
	 const home = () => history.push('/');
	 const menu = () => history.push('/menu');
	 const posts = () => history.push('/posts');
	 const apropos = () => history.push('/apropos');
	 const newmusic = () => history.push('/newmusic');
	 const music = () => history.push('/music');

	return (
		<nav className="navbar is-black is-fixed-top">
			<div className="navbar-brand">
				{/* <a className="navbar-item" href="http://localhost:3000/">
    			</a> */}
    			<a className="navbar-item" href={() => false} onClick={home}>
				<img src={Logo} width="75" height="75" alt=""/>
      			<p className="title is-4 has-text-white">Disc'Guard</p>
    			</a>

    			<a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" href={() => false}>
      				<span aria-hidden="true"></span>
      				<span aria-hidden="true"></span>
      				<span aria-hidden="true"></span>
    			</a>
  			</div>

			<div id="navbarBasicExample" className="navbar-menu">
				
			<div className="navbar-end">
					<div className="navbar-item">
					<a href={() => false} onClick={menu}>
							Manage Servers
					</a>
					</div>
					<div className="navbar-item">
					<a href="https://status.discguard.xyz">
							Status
					</a>
					</div>
					<div className="navbar-item">
					<a href={() => false} onClick={apropos}>
							About Us
					</a>
					</div>
					<div className="navbar-item">
						<a href="https://discguard.xyz/#features" >
						{/* <a href="http://localhost:3000/#features" > */}
							Features	
					</a>
					</div>
					<div className="navbar-item">
					<a href={() => false} onClick={posts}>
							Announcements
					</a>
					</div>
					<hr className="navbar-divider"/>
					<div className="navbar-item">
						<Panel />
					</div>
					<div className="navbar-item">
						<AuthOptions />
					</div>
			</div>
			</div>
			
		</nav>
	);
}
