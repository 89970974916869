// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

export function notify(toast, type, message) {
    console.log(type)
    console.log(message)
    toast.configure({
        autoClose: 5000,
        draggable: false,
        //same as ToastContainer props
      })
    if (type == 'error') {
        toast.error(message, { autoClose: 5000,  })
    }
    if (type == 'warn') {
        toast.warn(message, { autoClose: 5000,  })
    }
    if (type == 'info') {
        toast.info(message, { autoClose: 5000,  })
    }
    if (type == 'success') {
        toast.success(message, { autoClose: 5000,  })
    }
}