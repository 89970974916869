import React from 'react';
import Logo from '../../assets/logo.png';
import Feature1 from '../../assets/Feature1.png';
import Feature2 from '../../assets/DiscGuard RaidMode.png';
import Feature3 from '../../assets/DiscGuard_Firewall3.gif';

export default function Home() {
	return (
		<section className="container is-fullheight-with-navbar">
			<section className="hero">
				<div className="hero-body">
					<div className="container has-text-centered">
						<h1 className="title is-spaced has-text-white">
							What is Disc'Guard ?
						</h1>
						<h2 className="subtitle has-text-white">
						Discord Guardian is a raid protection discord bot. It helps you manage your admin team with an innovative neutralization system.
						</h2>
						<div className="buttons is-centered">
							<a className="control button is-blue" href="/register">
								Login
							</a>
							<a className="control button is-light" href="/apropos">
								More info!
							</a>
						</div>
					</div>
				</div>
			</section>
			<section className="section" id="features">
				<hr className="my-6" />
				<div className="container py-4">
					<h2 className="title has-text-centered has-text-white mb-6">
						Our Features
					</h2>
					<div className="columns is-vcentered">
						<div className="column">
							<h2 className="title has-text-white has-text-centered-mobile is-spaced">
								AntiRaid
							</h2>
							<p className="subtitle has-text-white has-text-centered-mobile">
							It works so that whoever wants to create/modify/delete a channel/role or ban/kick 2 people in a row or spam messages/mentions/links without being whitelist by the server owner, then he will be removed from any role or softbanned
							</p>
						</div>
						<div className="column">
							<img src={Feature1} alt="" className="myfeature"/>
						</div>
					</div>
					<hr className="my-6" />
					<div className="columns is-vcentered">
						<div className="column is-hidden-mobile">
							<img src={Feature2} alt="" className="myfeature"/>
						</div>
						<div className="column is-desktop">
							<h2 className="title has-text-white has-text-right has-text-centered-mobile is-spaced">
							RaidMode
							</h2>
							<p className="subtitle has-text-white has-text-right has-text-centered-mobile">
							Raidmode is an automatic raid detection system, depending on the AntiRaid settings on your server, if one of the rules is violated 3 times consecutively then the user will be automatically banned and the arrival of new members will be temporarily blocked and the RG Team will be notified that your server is being raided.
							</p>
							<div className="column is-hidden-desktop is-hidden-tablet is-hidden-widescreen is-hidden-fullhd">
							<img src={Feature2} alt="" className="myfeature"/>
						</div>
						</div>
					</div>
					<hr className="my-6" />
					<div className="columns is-vcentered">
						<div className="column">
							<h2 className="title has-text-white has-text-centered-mobile is-spaced">
							Firewall	
							</h2>
							<p className="subtitle has-text-white has-text-centered-mobile">
							It aims to protect you from massive intrusions into your server by proposing a simple problem that will need to be solved and that bots cannot do without a human brain behind. To explain it simply, it's like a Captcha.
							</p>
						</div>
						<div className="column">
							<img src={Feature3} alt="" className="myfeature is-medium" style={{ marginLeft: 60 + 'px' }}/>
						</div>
					</div>
					{/* <hr className="my-6" /> */}
					{/* <div className="columns is-vcentered">
						<div className="column is-hidden-mobile">
							<img src={Feature1} alt="" className="myfeature"/>
						</div>
						<div className="column is-desktop">
							<h2 className="title has-text-white has-text-right has-text-centered-mobile is-spaced">
							La Whitelist
							</h2>
							<p className="subtitle has-text-white has-text-right has-text-centered-mobile">
							Pour éviter ce perdre ces propres rôles et ceux de votre staff, on a crée une whitelist qui permet de mettre les identifiants de vos staff et ou l'antiraid ne marchera pas sur eux.
							</p>
							<div className="column is-hidden-desktop is-hidden-tablet is-hidden-widescreen is-hidden-fullhd">
							<img src={Feature1} alt="" className="myfeature"/>
						</div>
						</div>
					</div> */}
				</div>
			</section>
			<section className="section">
				<div className="container has-text-centered has-text-white">
					<h2 className="title has-text-white">WhiteList</h2>
					<p>
					To avoid losing your own roles and those of your staff, we have created a whitelist that allows you to put the ID's of your staff and where the AntiRaid will not work on them.
					</p>
				</div>
			</section>
			<section className="section">
			<div className="container has-text-centered">
				<div className="columns is-centered">
					<div className="column is-7">
						<h1 className="title has-text-white">And much more features</h1>
						<p className="subtitle has-text-white">So go ahead and invite him</p>
					</div>
				</div>
				<button className="button is-blue" href="https://discord.com/api/oauth2/authorize?client_id=817805166932656149&permissions=1644905889023&scope=bot%20applications.commands">
					Invite the bot
				</button>
			</div>
		</section>
		</section>
	);
}
