import React from 'react';
import FileFR from '../../assets/DiscGuard Politiques de Confidentialités.pdf'
import FileEN from '../../assets/DiscGuard Privacy Policy.pdf'


export default function Privacy() {

	return (
		<section class="section is-large allsection">
			<div class="container has-text-centered">
				<h2 class="title is-spaced has-text-white">
					Here are our Privacy Policies
				</h2>
				<p class="subtitle has-text-white">
					You can download them in different languages:
				</p>
				<div className="buttons" style={{justifyContent: "center"}}>
                <a class="button is-blue" href={FileFR} download="DiscGuard Politiques de Confidentialités.pdf">
					Français
				</a>
                <a class="button is-blue" href={FileEN} download="DiscGuard Privacy Policy.pdf">
						English
				</a>
					</div>
			</div>
		</section>
	);
}
