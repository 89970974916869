import React from 'react';

class ServerListItem extends React.Component {
	constructor(props) {
		super(props);

		this.onShowUser = this.onShowUser.bind(this);
	}
	onShowUser() {
		window.location.pathname = `/admin/users/${this.props.user._id}`;
	}

	render() {
        const { guilds } = this.props;
		return (
			<div className="panel-block is-block py-3 px-4">
				<a className="userlevel" href={() => false}>
				{/* <a className="userlevel" onClick={this.onShowUser} href={() => false}> */}
					<div className="userlevel-left">
						<div className="userlevel-item has-text-white"><strong>Nom: </strong>{guilds.name}</div>
						
						<div className="userlevel-item has-text-white"><strong>ID: </strong>{guilds.id}</div>
					</div>
					<div className="userlevel-right">
						<div className="userlevel-item">
							<button className="button is-blue" disabled>Voir plus</button>
							{/* <button className="button is-darkred" onClick={this.onShowUser}>Voir plus</button> */}
						</div>
					</div>
				</a>
			</div>
		);
	}
}

export default ServerListItem;
