import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import Axios from 'axios';
import ErrorNotice from '../misc/ErrorNotice';
import { API_DOMAIN } from '../../config';

export default function DeletePost({ match }) {
	const [error, setError] = useState();
	const { userData } = useContext(UserContext);
	const history = useHistory();
	const submit = async (e) => {
		e.preventDefault();
		try {
			await Axios.delete(
				`${API_DOMAIN}api/posts/${window.location.pathname.split('/')[2]}`
				// `${API_DOMAIN}posts/5fd37c171f9e1cc61d7c8516`
			);
			history.push('/posts');
		} catch (err) {
			console.log(err);
			err.response.data.msg && setError(err.response.data.msg);
		}
	};	
	return (
		<div>
			{' '}
			{error && (
				<ErrorNotice message={error} clearError={() => setError(undefined)} />
			)}{' '}
			{userData.discordId && userData.roles === "Admin" ? (
				<button className="button is-blue" onClick={submit}>
					Delete{' '}
				</button>
			) : (
				<> </>
			)}{' '}
		</div>
	);
}
