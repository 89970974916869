import React from 'react';
import Axios from 'axios';
import { API_DOMAIN } from '../../config';
import { getUserDetails } from '../utils/api';
class Panel extends React.Component {
	state = {
		connectedUser: {},
		authRes: {}
	};
	componentDidMount() {
		this.getConnectedUser();
	}
	async getConnectedUser() {
		let token = localStorage.getItem('auth-token');
		if (token === null) {
			return;
		}
		const tokenRes = await Axios.post(
			`${API_DOMAIN}api/auth/tokenIsValid`,
			null,
			{
				headers: { 'auth-token': token }
			}
		);
		// console.log(tokenRes.data)
		if (tokenRes.data.status) {
			getUserDetails().then(({ data }) => {
				this.setState({ connectedUser: data });
			})
			// const userRes = await Axios.get(`${API_DOMAIN}api/auth/loggedIn`, {
			// 	headers: { 'auth-token': token }
			// });
			// console.log(userRes.data);
			// console.log(this.state.connectedUser)
			try {
				const resIsStaff = await Axios.post(
					`${API_DOMAIN}api/auth/isStaff`,
					tokenRes.data.user
				);
				this.setState({ authRes: resIsStaff.data });
			} catch (error) {
				console.log(error);
			}
		}
	}
	goAdmin() {
		window.location.pathname = `/admin/`;
	}
	render() {
		return (
			<>
				{this.state.authRes.status === '200' ? (
					<button className="button is-blue" onClick={this.goAdmin}>
						Panel Admin
					</button>
				) : (
					<></>
				)}
			</>
		);
	}
}
export default Panel;
