import React from 'react';
import Axios from 'axios';
import DeletePost from './DeletePost';
import Logo from '../../assets/logo.png';
import { API_DOMAIN } from '../../config';

class Post extends React.Component {
	state = {
		post: {},
		connectedUser: {},
		authRes: {},
	};
	componentDidMount() {
		this.getPosts();
	}

	async getPosts() {
		const res = await Axios.get(
			`${API_DOMAIN}api/posts/${this.props.match.params.id}`
		);
		this.setState({ post: res.data });
	}
	renderTitle() {
		return { title: this.state.post.title };
	}
	renderImage() {
		if (this.state.post.image == null || 'NoImage') {
			return Logo;
		} else {
			return { image: `${this.state.post.image}` };
		}
	}
	renderPostDate() {
		return { date: `${this.state.post.createdAt}` };
	}
	renderDate(dateString) {
		const date = new Date(dateString);

		const monthNames = [
			'Janvier',
			'Fevrier',
			'Mars',
			'Avril',
			'Mai',
			'Juin',
			'Juillet',
			'Août',
			'Septembre',
			'Octobre',
			'Novembre',
			'Decembre'
		];

		return `${date.getDate()} ${
			monthNames[date.getMonth()]
		} ${date.getFullYear()}`;
	}
	renderHTMLTitle1() {
		if (this.state.post.htmlTitle1 === 'NoText') {
			return { __html: `` };
		} else {
			return { __html: `<h1>${this.state.post.htmlTitle1}</h1>` };
		}
	}
	renderHTMLText1() {
		if (this.state.post.htmlText1 === 'NoText') {
			return { __html: `` };
		} else {
			return { __html: `<h1>${this.state.post.htmlText1}</h1>` };
		}
	}
	renderHTMLTitle2() {
		if (this.state.post.htmlTitle2 === 'NoText') {
			return { __html: `` };
		} else {
			return { __html: `<h1>${this.state.post.htmlTitle2}</h1>` };
		}
	}
	renderHTMLText2() {
		if (this.state.post.htmlText2 === 'NoText') {
			return { __html: `` };
		} else {
			return { __html: `<h1>${this.state.post.htmlText2}</h1>` };
		}
	}
	renderHTML() {
		if (this.state.post.html === 'NoText') {
			return { __html: `` };
		} else {
			return { __html: `<h1>${this.state.post.html}</h1>` };
		}
	}

	renderPosth1(html) {
		return <p dangerouslySetInnerHTML={html}></p>;
	}
	renderPosth3(html) {
		return <p dangerouslySetInnerHTML={html}></p>;
	}
	// // renderHTML() {
	// // 	return { __html: this.state.post.html };
	// // }
	// renderPost() {
	// 	return <div>{this.state.post.html}</div>;
	// }
	render() {
		return (
			// <section className="section allsection">
			// 	<div>{this.renderPost()}</div>
			// </section>
			<section className="section allsection">

				<DeletePost />
				<div className="container has-text-centered py-4">
					<h2 className="title  has-text-white mb-6">{this.state.post.title}</h2>
					<div className="block">
						<img className="is-16by9 mb-6" src={this.renderImage()} alt="" />
					</div>
					<h4 className="title  has-text-white is-spaced is-3">
						{this.renderDate(this.state.post.createdAt)}
					</h4>
					<div className="subtitle  has-text-white is-4">
						{this.renderPosth1(this.renderHTMLTitle1())}
					</div>
					<div className="subtitle  has-text-white is-5">
						{this.renderPosth3(this.renderHTMLText1())}
					</div>
					<div className="subtitle  has-text-white is-4">
						{this.renderPosth1(this.renderHTMLTitle2())}
					</div>
					<div className="subtitle  has-text-white is-5">
						{this.renderPosth3(this.renderHTMLText2())}
					</div>
					<div className="subtitle  has-text-white is-4">
						{this.renderPosth3(this.renderHTML())}
					</div>
				</div>
			</section>
		);
	}
}
export default Post;
