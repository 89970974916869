import axios from 'axios';
import { API_DOMAIN } from '../../config';

export function getUserDetails() {
    return axios.get(`${API_DOMAIN}api/auth/`, {
        withCredentials: true
    });
}
export function logoutUserDetails() {
    return axios.get(`${API_DOMAIN}api/auth/logout`, {
        withCredentials: true
    });
}

export function getGuild(guildId) {
    return axios.get(
        `${API_DOMAIN}api/discord/guilds/${guildId}`, { withCredentials: true }
    );
}
export function getGuilds() {
    return axios.get(
        `${API_DOMAIN}api/discord/guilds`, { withCredentials: true }
    );
}

export function hasAccessToGuild(guildId) {
    return axios.get(
        `${API_DOMAIN}api/discord/guilds/verify/${guildId}/`, { withCredentials: true }
    );
}

export function getGuildConfig(guildId) {
    return axios.get(
        `${API_DOMAIN}api/discord/guilds/${guildId}/config`, { withCredentials: true }
    );
}
export function getGuildChannels(guildId) {
    return axios.get(
        `${API_DOMAIN}api/discord/guilds/${guildId}/channels`, { withCredentials: true }
    );
}
export function getGuildMembers(guildId) {
    return axios.get(
        `${API_DOMAIN}api/discord/guilds/${guildId}/members`, { withCredentials: true }
    );
}

export function updateGuildPrefix(guildId, prefix) {
    return axios.put(
        `${API_DOMAIN}api/discord/guilds/${guildId}/prefix`, { prefix }, { withCredentials: true }
    );
}
export function updateGuildLanguage(guildId, language) {
    return axios.put(
        `${API_DOMAIN}api/discord/guilds/${guildId}/language`, { language }, { withCredentials: true }
    );
}
export function updateGuildLogChannel(guildId, logChannel) {
    return axios.put(
        `${API_DOMAIN}api/discord/guilds/${guildId}/logs`, { logChannel }, { withCredentials: true }
    );
}
export function updateGuildModLogChannel(guildId, logChannel) {
    return axios.put(
        `${API_DOMAIN}api/discord/guilds/${guildId}/modlogs`, { logChannel }, { withCredentials: true }
    );
}