import React, { useState } from 'react';
import Logo from '../../assets/logo.png';


export default function Home() {
  return (
    <section className="hero is-fullheight-with-navbar">
      {/* <div className="hero-body">
        <div className="container">
          <p className="title has-text-white ">
            Disc'Guard Bot
          </p>
        </div>
            <img className="image" src={Logo} width="500" height="500"/>
      </div> */}
      
      {/* <img src="../../assets/png-clipart-arrow-arrow-down-arrow.png"/> */}
      <div className="hero-body">
      <div className="columns is-vcentered is-mobile">
						<div className="column">
							<h2 className="title has-text-white is-spaced">
								Disc'Guard
							</h2>
							<p className="subtitle has-text-white"> The best protection bot for your servers</p>
							<div className="buttons">
								<a className="button is-blue" href="https://discord.com/api/oauth2/authorize?client_id=817805166932656149&permissions=1644905889023&scope=bot%20applications.commands">
									Invite!
								</a>
								<a className="button is-blue" href="/menu">
                My servers
								</a>
							</div>
						</div>
						<div className="column is-hidden-mobile">
							<img src={Logo} alt="" />
						</div>
        </div>
        </div>
    </section>

  );
}
