import React from 'react';
import ServerListItem from './ServerListItem';
import Axios from 'axios';
import { API_DOMAIN } from '../../../config';
import { getUserDetails } from '../../utils/api';
class ServerList extends React.Component {
	state = {
        guilds: [],
        connectedUser: {},
        authRes: {},
	};

	renderList() {
		return this.state.guilds.map((guild, i) => {
			return <ServerListItem guilds={guild} key={guild.id} />;
		});
	}

	componentDidMount() {
        this.getConnectedUser();
        this.renderAllGuilds();
	}

    async renderAllGuilds() {
		const res = await Axios.get(`${API_DOMAIN}api/discord/botguilds`);
		this.setState({ guilds: res.data });
    }
    
    async getConnectedUser() {
		let token = localStorage.getItem('auth-token');
		if (token === null) {
			return;
		}
		const tokenRes = await Axios.post(`${API_DOMAIN}api/auth/tokenIsValid`, null, {
			headers: { 'auth-token': token }
		});
		// console.log(tokenRes.data)
		if (tokenRes.data.status) {
			getUserDetails().then(({ data }) => {
				this.setState({ connectedUser: data });
			})
			// const userRes = await Axios.get(`${API_DOMAIN}api/auth/`, {
			// 	headers: { 'auth-token': token }
			// });
			// console.log(userRes);
			// console.log(this.state.connectedUser)
			try {
				const resIsStaff = await Axios.post(
					`${API_DOMAIN}api/auth/isStaff`,
					tokenRes.data.user
				);
				this.setState({ authRes: resIsStaff.data });
			} catch (error) {
				console.log(error);
			}
		}
    }
    goHome() {
		window.location.pathname = `/`;
	}

	render() {
		return (
            <>
                {this.state.authRes.status === '200' ? (
                    <section className="section allsection">
                        {/* <div className="box"> */}
                        <nav className="breadcrumb" aria-label="breadcrumbs">
                            <ul>
                                <li className="is-active">
                                    <a href={() => false} aria-current="page">
                                        Serveurs
								</a>
                                </li>
                            </ul>
                        </nav>
                        {/* </div> */}

                        <div className="panel">
                            <h2 className="title has-text-centered has-text-white mb-6">Serveurs</h2>
                            <nav className="userlevel">
                                <div className="userlevel-left">
                                    <div className="userlevel-item">
                                        <p className="subtitle has-text-white is-5">
                                            <strong>{this.state.guilds.length}</strong> serveurs
									</p>
                                    </div>
                                    <div className="userlevel-item">
                                        <div className="field has-addons">
                                            <p className="control">
                                                <input
                                                    className="input"
                                                    type="text"
                                                    placeholder="Trouver un serveur"
                                                />
                                            </p>
                                            <p className="control">
                                                <button className="button is-blue" disabled>Chercher</button>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="userlevel-right">
								<p className="userlevel-item has-text-white">
									<strong>Tous</strong>
								</p>
								<p className="userlevel-item has-text-white">
									<a>Utilisateurs</a>
								</p>
								<p className="userlevel-item has-text-white">
									<a>Modérateurs</a>
								</p>
								<p className="userlevel-item v">
									<a>Admins</a>
								</p>
							</div> */}
                            </nav>
                            <div className="panel-block is-block py-3 px-4">
                                {this.renderList()}
                            </div>
                        </div>
                        <nav>
                            <div
                                className="pagination is-centered"
                                role="navigation"
                                aria-label="pagination"
                            >
                                <a className="pagination-previous has-text-white" href={() => false} disabled>
                                    Precedent
							</a>
                                <a className="pagination-next has-text-white" href={() => false} disabled>
                                    Suivant
							</a>
                                <ul className="pagination-list">
                                    <li>
                                        <a
                                            className="pagination-link is-current"
                                            aria-label="Page 1"
                                            aria-current="page"
                                            href={() => false}
                                        >
                                            1
									</a>
                                    </li>
                                    {/* <li><a className="pagination-link" aria-label="Goto page 2">2</a></li>
        <li><a className="pagination-link" aria-label="Goto page 3">3</a></li>
        <li><span className="pagination-ellipsis">…</span></li>
        <li><a className="pagination-link" aria-label="Goto page 47">5</a></li>
        <li><span className="pagination-ellipsis">…</span></li>
        <li><a className="pagination-link" aria-label="Goto page 86">10</a></li> */}
                                </ul>
                            </div>
                        </nav>
                        {/* </div> */}
                    </section>
                ) : (
                    <section className="section allsection">
						<div className="container has-text-centered">
							<div className="columns is-centered">
								<div className="column is-7">
									<h1 className="title has-text-white is-1">403</h1>
									<p className="subtitle has-text-white is-3">Accès interdit</p>
									<p>
										Woops! On dirait que vous cherchez quelque chose
										d'interdit...{' '}
									</p>
								</div>
							</div>
							<button
								className="button is-blue"
								onClick={this.goHome.bind()}
							>
								Retour à la maison
							</button>
						</div>
					</section>
                )}
			</>
		);
	}
}

export default ServerList;
