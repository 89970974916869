import React from 'react';

export default function Apropos() {
	return (
		<>
			<section className="section is-medium allsection">
				<div className="container has-text-centered has-text-white">
					<h2 className="title has-text-white">Discord Guardian</h2>
					<p>
					Disc'Guard is a raid protection discord bot. It helps you manage your admin team with an innovative neutralization system.
					</p>
				</div>
			</section>
			<section className="section">
				<div className="container has-text-centered py-4">
					<h2 className="title has-text-white">Le Staff de Disc'Guard</h2>
					<p className="subtitle mb-6 has-text-white">
					Here are the Disc'Guard staff who do what the bot is now
					</p>
					<div className="columns is-centered is-multiline py-5">
						<div className="column is-6 is-3-widescreen mb-6">
							<div className="level">
								<div className="level-item">
									<figure className="image is-128x128">
										<img
											className="is-rounded"
											src={require('../../assets/user.png')}
											alt=""
										/>
									</figure>
								</div>
							</div>
							<h5 className="title is-5 has-text-white">Tim S.</h5>
							<p className="subtitle is-6 has-text-white">
							Creator / Bot Developer / Dashboard Developer
							</p>
						</div>
						<div className="column is-6 is-3-widescreen mb-6">
							<div className="level">
								<div className="level-item">
									<figure className="image is-128x128">
										<img
											className="is-rounded"
											src={require('../../assets/user.png')}
											alt=""
										/>
									</figure>
								</div>
							</div>
							<h5 className="title is-5 has-text-white">Mathys L.</h5>
							<p className="subtitle is-6 has-text-white">
								Administrator / RG Team Manager
							</p>
						</div>
						<div className="column is-6 is-3-widescreen mb-6">
							<div className="level">
								<div className="level-item">
									<figure className="image is-128x128">
										<img
											className="is-rounded"
											src={require('../../assets/user.png')}
											alt=""
										/>
									</figure>
								</div>
							</div>
							<h5 className="title is-5 has-text-white">Esteban F.</h5>
							<p className="subtitle is-6 has-text-white">
								Support / RG Team
							</p>
						</div>
						<div className="column is-6 is-3-widescreen mb-6">
							<div className="level">
								<div className="level-item">
									<figure className="image is-128x128">
										<img
											className="is-rounded"
											src={require('../../assets/user.png')}
											alt=""
										/>
									</figure>
								</div>
							</div>
							<h5 className="title is-5 has-text-white">We Recruit</h5>
							<p className="subtitle is-6 has-text-white">Job</p>
						</div>
						<div className="column is-6 is-3-widescreen mb-6">
							<div className="level">
								<div className="level-item">
									<figure className="image is-128x128">
										<img
											className="is-rounded"
											src={require('../../assets/user.png')}
											alt=""
										/>
									</figure>
								</div>
							</div>
							<h5 className="title is-5 has-text-white">We Recruit</h5>
							<p className="subtitle is-6 has-text-white">Job</p>
						</div>
						<div className="column is-6 is-3-widescreen mb-6">
							<div className="level">
								<div className="level-item">
									<figure className="image is-128x128">
										<img
											className="is-rounded"
											src={require('../../assets/user.png')}
											alt=""
										/>
									</figure>
								</div>
							</div>
							<h5 className="title is-5 has-text-white">We Recruit</h5>
							<p className="subtitle is-6 has-text-white">Job</p>
						</div>
						<div className="column is-6 is-3-widescreen mb-6">
							<div className="level">
								<div className="level-item">
									<figure className="image is-128x128">
										<img
											className="is-rounded"
											src={require('../../assets/user.png')}
											alt=""
										/>
									</figure>
								</div>
							</div>
							<h5 className="title is-5 has-text-white">We Recruit</h5>
							<p className="subtitle is-6 has-text-white">Job</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
