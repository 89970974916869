import React from 'react';

export default function Page404() {
	const goHome = () => (window.location.pathname = '/')

	return (
		<section className="section is-large allsection">
			<div className="container has-text-centered">
				<div className="columns is-centered">
					<div className="column is-7">
						<h1 className="title is-1 has-text-white">404</h1>
						<p className="subtitle is-3 has-text-white">Page not found</p>
						<p>Looks like you are lost ...</p>
					</div>
				</div>
				<button className="button is-blue" onClick={goHome.bind()}>
					Go Home
				</button>
			</div>
		</section>
	);
}
