import React from 'react';


export default function Login() {
    React.useEffect(() => {
        // window.location.href = "http://localhost:4000/api/auth/discord"
        window.location.href = "https://api.discguard.xyz/api/auth/discord"
	}, []);

	return (
        
		<section className="section is-large allsection">
			<div className="container has-text-centered">
				<div className="columns is-centered">
					<div className="column is-7">
						<h1 className="title is-1 has-text-white">308</h1>
						<p className="subtitle is-3 has-text-white">Redirection...</p>
						<p>You will be redirected</p>
					</div>
				</div>
            </div>
        </section>
	);
}
