import React, { useContext } from 'react';
import UserContext from '../../context/UserContext';
import { logoutUserDetails } from '../utils/api';


export default function Login() {
    const {setUserData } = useContext(UserContext);

    const redirect = () => {
        setUserData({
			token: undefined,
			user: undefined
		});
		localStorage.setItem('auth-token', '');
		logoutUserDetails();
		window.location.href = 'https://discguard.xyz/';
		// window.location.href = 'http://localhost:3000/';
    }


    React.useEffect(() => {
        redirect()
	}, []);
	return (
        
		<section className="section is-large allsection">
			<div className="container has-text-centered">
				<div className="columns is-centered">
					<div className="column is-7">
						<h1 className="title is-1 has-text-white">308</h1>
						<p className="subtitle is-3 has-text-white">Redirection...</p>
						<p>You will be redirected</p>
					</div>
				</div>
			</div>
        </section>
	);
}