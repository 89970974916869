import React, { useState } from 'react';
import DashboardListItem from './DasboardListItem';
import discordServerLogo from '../../assets/discordServer.png';
import { Select, Switch, FormControl, FormLabel, Box } from '@chakra-ui/react';
import {
	getGuild,
	getGuildConfig,
	getUserDetails,
	updateGuildPrefix,
	updateGuildLanguage,
	updateGuildLogChannel,
	updateGuildModLogChannel,
	getGuildChannels,
	getGuildMembers,
	hasAccessToGuild
} from '../utils/api';
import ErrorNotice from '../misc/ErrorNotice';
import { notify } from '../utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DashboardList({ history, match }) {
	const [user, setUser] = React.useState(null);
	const [guild, setGuild] = React.useState({});
	const [isAuthorized, setIsAuthorized] = React.useState({});
	const [channels, setChannels] = React.useState(null);
	const [members, setMembers] = React.useState(null);
	const [loading, setLoading] = React.useState(true);
	const [config, setConfig] = React.useState({});
	const [prefix, setPrefix] = React.useState();
	const [language, setLanguage] = React.useState();
	const [logs, setLogs] = React.useState();
	const [modlogs, setModlogs] = React.useState();
	const [error, setError] = useState();

	React.useEffect(() => {
		getUserDetails()
			.then(({ data }) => {
				setUser(data);
				return hasAccessToGuild(match.params.id);
			})
			.then(({ data }) => {
				setIsAuthorized(data);
				return getGuild(match.params.id);
			})
			.then(({ data }) => {
				setGuild(data[0]);
				return getGuildConfig(match.params.id);
			})
			.then(({ data }) => {
				setConfig(data);
				console.log(data)
				return getGuildChannels(match.params.id);
			})
			.then(({ data }) => {
				setChannels(data);
				return getGuildMembers(match.params.id);
			})
			.then(({ data }) => {
				setMembers(data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				// history.push('/')
				setLoading(false);
			});
	}, []);
	const goHome = () => (window.location.pathname = '/');
	const icon = (guildId, guildIcon) => {
		if (guildIcon == null) {
			return discordServerLogo;
		} else {
			return `https://cdn.discordapp.com/icons/${guildId}/${guildIcon}.png`;
		}
	};
	const submitGeneral = async (e) => {
		e.preventDefault()
		try {
			console.log(prefix)
			console.log(language)
			// if (prefix == undefined)
			if (prefix == undefined && language == undefined) return;
			// if (prefix == undefined) {
			// 	console.log(config.prefix)
			// 	setPrefix(config.prefix)
			// }
			// if (language == undefined) {
			// 	setLanguage(config.language)
			// }
			// console.log(prefix == undefined)
			const update1 = await updateGuildPrefix(match.params.id, prefix);
			const update2 = await updateGuildLanguage(match.params.id, language);
			notify(toast, 'success', 'Updated !');
		} catch (error) {
			notify(toast, 'error', error.response.data.msg);
		}
	};
	const submitLogs = async (e) => {
		e.preventDefault()
		try {
			console.log(logs)
			console.log(modlogs)
			if (logs == undefined && modlogs == undefined) return;

			const update1 = await updateGuildLogChannel(match.params.id, logs);
			const update2 = await updateGuildModLogChannel(match.params.id, modlogs);
			notify(toast, 'success', 'Updated !');
		} catch (error) {
			notify(toast, 'error', error.response.data.msg);
		}
	};
	const submitAntiRaid = async (e) => {
		e.preventDefault()
		try {
			console.log(logs)
			console.log(modlogs)
			if (logs == undefined && modlogs == undefined) return;

			const update1 = await updateGuildLogChannel(match.params.id, logs);
			const update2 = await updateGuildModLogChannel(match.params.id, modlogs);
			notify(toast, 'success', 'Updated !');
		} catch (error) {
			notify(toast, 'error', error.response.data.msg);
		}
	};

	const updateGuildPrefixParent = async (prefix) => {
		try {
			const update = await updateGuildPrefix(match.params.id, prefix);
			notify(toast, 'success', 'Updated !');
		} catch (error) {
			notify(toast, 'error', error.response.data.msg);
		}
	};

	const updateGuildLogChannelParent = async (logChannel) => {
		try {
			const update = await updateGuildLogChannel(match.params.id, logChannel);
			notify(toast, 'success', 'Updated !');
		} catch (error) {
			notify(toast, 'error', error.response.data.msg);
		}
	};
	const updateGuildModLogChannelParent = async (logChannel) => {
		try {
			const update = await updateGuildModLogChannel(
				match.params.id,
				logChannel
			);
			notify(toast, 'success', 'Updated !');
		} catch (error) {
			notify(toast, 'error', error.response.data.msg);
		}
	};
	return (
		!loading && (
			<>
				{isAuthorized.status == '200' ? (
					<section className="section is-medium is-fullheight-with-navbar">
						<h2
							className="title has-text-centered has-text-white mb-6"
							style={{ marginBottom: 6.5 + 'rem' }}
						>
							Server Settings
						</h2>
						{/* {error && (
							<ErrorNotice
								message={error}
								clearError={() => setError(undefined)}
							/>
						)}{' '} */}
						{/* <DashboardListItem
							user={user}
							config={config}
							channels={channels}
							updatePrefix={updateGuildPrefixParent}
							updateLogChannel={updateGuildLogChannelParent}
							updateModLogChannel={updateGuildModLogChannelParent}
						/> */}

						<div class="columns is-multiline has-text-centered">
							<div class="column is-one-third">
								<div className="dashcard">
									<div className="dashcard-header">
										<div className="dahscard-header-image image is-128x128 ">
											<a href={`/dashboard/${guild.id}`}>
												<img
													className="is-rounded"
													src={icon(guild.id, guild.icon)}
													alt=""
												/>
											</a>
										</div>
										<div className="dashcard-header-title is-centered has-text-white">
											{guild.name}
										</div>
									</div>
									<div className="dashcard-content">
										<div class="level-item is-mobile has-text-centered has-text-white">
											<div>
												<p class="heading">Members</p>
												<p class="title has-text-white">{members.length}</p>
											</div>
										</div>
										<div class="level-item is-mobile has-text-centered  has-text-white">
											<div>
												<p class="heading">Bot's</p>
												<p class="title has-text-white">{members.filter((member) => member.user.bot).length}</p>
											</div>
										</div>
										<div class="level-item is-mobile has-text-centered  has-text-white">
											<div>
												<p class="heading">Owner</p>
												<p class="title has-text-white">{guild.owner ? "Oui" : "Non" }</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="column is-one-third">
								<div className="dashcard">
									{/* <div className="embed-color"></div> */}
									{/* <div className="embed-card"> */}
										<header className="dashboardcard-header">
					<p className="dashboardcard-header-title  has-text-white ">📝General Configuration</p>
				</header>
										<form onSubmit={submitGeneral}>

					<div className="dashboardcard-content  has-text-white">
						Prefix
						<div className="field">
							<div className="control">
								<input
								
									className="input"
									type="text"
									onChange={(e) => setPrefix(e.target.value)
									}
									defaultValue={config.prefix}
								/>
							</div>
						</div>
					</div>
					<div className="dashboardcard-content  has-text-white">
						Language
						<div className="field">
							{/* <div className="dropdown"> */}
							<select
									name="language"
														onChange={(e) => setLanguage(e.target.value)}
														className="input"
								>
                                    
										<option
											value="fr-FR"
											selected={"fr-FR" === config.language}
										>
										Français					
										</option>
										<option
											value="en-EN"
											selected={"en-EN" === config.language}
										>
											English
										</option>
								</select>
							{/* </div> */}
						</div>
					</div>
					<footer className="dashboardcard-footer">
						<div className="dashboardcard-footer-item">
							<button
								className="button is-blue"
								type="submit"
														children="Update"
														onClick={(e) => {
															if (prefix == undefined && language == undefined) {
																notify(toast, 'warn', 'Vous n\'avez rien changé !')
															} else if (prefix == undefined && language !== undefined) {
																setPrefix(config.prefix)
															} else if (language == undefined && prefix !== undefined) {
																setLanguage(config.language)
															}
														}}
							/>
						</div>
											</footer>
											</form>
										{/* <div className="embed-block">
											<div className="embed-content">
												<div className="embed-title">Test</div>
											</div>
										</div>
											<div className="embed-footer"><span>hum</span></div> */}
										
									{/* </div> */}
								</div>
							</div>
							<div class="column is-one-third">
							<div className="dashcard">
									{/* <div className="embed-color"></div> */}
									{/* <div className="embed-card"> */}
										<header className="dashboardcard-header">
					<p className="dashboardcard-header-title  has-text-white ">📝Logs Configuration</p>
				</header>
										<form onSubmit={submitLogs}>

					<div className="dashboardcard-content  has-text-white">
						Server Logs
						<div className="field">
												<select name="logChannel" className="input" onChange={(e) => setLogs(e.target.value)}>
		 						{channels.included.map((channel) => (
									 
		 							<option
		 								value={channel.id}
		 								selected={channel.id === config.plugins.logs}
		 							>
		 								{channel.name}
		 							</option>
		 						))}
		 					</select>
						</div>
					</div>
					<div className="dashboardcard-content  has-text-white">
						Moderation Logs
						<div className="field">
												<select name="modlogChannel" className="input" onChange={(e) => setModlogs(e.target.value)}>
		 						{channels.included.map((channel) => (
									 
		 							<option
		 								value={channel.id}
		 								selected={channel.id === config.plugins.modlogs}
		 							>
		 								{channel.name}
		 							</option>
		 						))}
		 					</select>
						</div>
					</div>
					<footer className="dashboardcard-footer">
						<div className="dashboardcard-footer-item">
							<button
								className="button is-blue"
								type="submit"
														children="Update"
														onClick={(e) => {
															if (logs == undefined && modlogs == undefined) {
																notify(toast, 'warn', 'Vous n\'avez rien changé !')
															} else if (logs == undefined && modlogs !== undefined) {
																setLogs(config.plugins.logs)
															} else if (logs == undefined && modlogs !== undefined) {
																setModlogs(config.plugins.modlogs)
															}
														}}
							/>
						</div>
											</footer>
											</form>
										{/* <div className="embed-block">
											<div className="embed-content">
												<div className="embed-title">Test</div>
											</div>
										</div>
											<div className="embed-footer"><span>hum</span></div> */}
										
									{/* </div> */}
								</div>
							</div>
						
						</div>
					</section>
				) : (
					<>
						{isAuthorized.status == '404' ? (
							<section className="section is-large allsection">
								<div className="container has-text-centered">
									<div className="columns is-centered">
										<div className="column is-7">
											<h1 className="title is-1 has-text-white">404</h1>
											<p className="subtitle is-3 has-text-white">
												Page not found
											</p>
											<p>Looks like you are lost ...</p>
										</div>
									</div>
									<button className="button is-blue" onClick={goHome.bind()}>
										Go Home
									</button>
								</div>
							</section>
						) : (
							<section className="section is-large allsection">
								<div className="container has-text-centered">
									<div className="columns is-centered">
										<div className="column is-7">
											<h1 className="title has-text-white is-1">403</h1>
											<p className="subtitle has-text-white is-3">
											Access forbidden
											</p>
											<p>
											Woops! Looks like you are looking for something forbidden ...
											</p>
										</div>
									</div>
									<button className="button is-blue" onClick={goHome.bind()}>
										Go Home
									</button>
								</div>
							</section>
						)}
					</>
				)}
			</>
		)
	);
}
