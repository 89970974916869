import React from 'react';
import Axios from 'axios';
import { API_DOMAIN } from '../../config';
import AnnounceIcon from '../../assets/bullhorn-solid.svg';
import UserIcon from '../../assets/users-solid.svg';
import ServerIcon from '../../assets/server-solid.svg';
import WaitIcon from '../../assets/edit-solid.svg';
import { getUserDetails } from '../utils/api';
class Manager extends React.Component {
	state = {
		user: {},
		users: {},
		guilds: {},
		commands: {},
		musicsStatus: {},
		connectedUser: {},
		authRes: {},
		posts: {}
	};
	componentDidMount() {
		this.getConnectedUser();
		this.renderAllUser();
		this.renderAllGuilds();
		this.renderAllCommands();
		// this.renderAllMusicsStatus();
		this.getPosts();
	}
	async renderAllUser() {
		const res = await Axios.get(`${API_DOMAIN}api/auth/all`);
		this.setState({ users: res.data });
	}
	// async renderAllMusics() {
	// 	const res = await Axios.get(`${API_DOMAIN}music/`);
	// 	let data = res.data;
	// 	let filterdStatus = data.filter((s) => {
	// 		return s.status === 'En Attente';
	// 	});
	// 	this.setState({ musics: data });
	// 	this.setState({ musicsStatus: filterdStatus });
	// }
	// async renderAllMusicsStatus() {
	// 	const res = await Axios.get('${API_DOMAIN}music/');
	// 	this.setState({ musics: res.data  });
	// }
	async getConnectedUser() {
		let token = localStorage.getItem('auth-token');
		if (token === null) {
			return;
		}
		const tokenRes = await Axios.post(`${API_DOMAIN}api/auth/tokenIsValid`, null, {
			headers: { 'auth-token': token }
		});
		// console.log(tokenRes.data)
		if (tokenRes.data.status) {
			getUserDetails().then(({ data }) => {
				this.setState({ connectedUser: data });
			})
			// const userRes = await Axios.get(`${API_DOMAIN}api/auth/`, {
			// 	headers: { 'auth-token': token }
			// });
			// console.log(userRes);
			// console.log(this.state.connectedUser)
			try {
				const resIsStaff = await Axios.post(
					`${API_DOMAIN}api/auth/isStaff`,
					tokenRes.data.user
				);
				this.setState({ authRes: resIsStaff.data });
			} catch (error) {
				console.log(error);
			}
		}
	}
	async getPosts() {
		const res = await Axios.get(`${API_DOMAIN}api/posts/`);
		this.setState({ posts: res.data });
	}
	async renderAllGuilds() {
		const res = await Axios.get(`${API_DOMAIN}api/discord/botguilds`);
		this.setState({ guilds: res.data });
	}
	async renderAllCommands() {
		const res = await Axios.get(`${API_DOMAIN}api/discord/commands`);
		this.setState({ commands: res.data });
	}
	goServersManager() {
		window.location.pathname = `/admin/servers/`;
	}
	goCommandsManager() {
		window.location.pathname = `/admin/commands/`;
	}
	goErrorsManager() {
		window.location.pathname = `/admin/errors/`;
	}
	goPosts() {
		window.location.pathname = `/posts/`;
	}
	goMail() {
		window.location.href = 'https://mail.discguard.xyz';
	}
	goUsers() {
		window.location.pathname = `/admin/users/`;
	}
	goHome() {
		window.location.pathname = `/`;
	}
	// 	async getAdmin() {
	// 		let data = this.state.connectedUser.id;
	// 		// let id = { id: "5fc2ffc79722c5607f0db5c5"}
	// 		console.log(data)
	// 		const resIsAdmin = await Axios.get(`${API_DOMAIN}users/isAdmin`, data);
	// 		console.log(resIsAdmin)
	// 	this.setState({ authRes: resIsAdmin.data });
	// }
	// renderName() {
	// 	return { displayName: this.state.user.displayName };
	// }
	render() {
		return (
			<>
				{this.state.authRes.status === '200' ? (
					<>
						<section className="section allsection">
							<div className="container has-text-centered">
								<h2 className="title has-text-white">Disc'Guard Admin</h2>
							</div>
						</section>
						<section className="section">
							<div className="container">
								<div className="columns is-multiline">
									<div className="column is-6-tablet is-3-desktop">
										<div className="box" onClick={this.goPosts}>
											<div className="media">
												<div className="media-left">
													<figure className="image is-48x48">
														<img src={AnnounceIcon} alt="" />
													</figure>
												</div>
												<div className="media-content has-text-right">
													<h3 className="title is-1 has-text-info">
														{this.state.posts.length}
													</h3>
												</div>
											</div>
											<h4 className="title">Annonces</h4>
											<progress
												className="progress is-info"
												role="progressbar"
												value={this.state.posts.length}
												max="100"
											></progress>
										</div>
									</div>
									<div className="column is-6-tablet is-3-desktop">
										<div className="box" onClick={this.goUsers}>
											<div className="media">
												<div className="media-left">
													<figure className="image is-48x48">
														<img src={UserIcon} alt="" />
													</figure>
												</div>
												<div className="media-content has-text-right">
													<h3 className="title is-1 has-text-danger">
														{this.state.users.length}
													</h3>
												</div>
											</div>
											<h4 className="title">Utilisateurs</h4>
											<progress
												className="progress is-danger"
												role="progressbar"
												value={this.state.users.length}
												max="100"
											></progress>
										</div>
									</div>
									<div className="column is-6-tablet is-3-desktop">
										<div className="box" onClick={this.goServersManager}>
											<div className="media">
												<div className="media-left">
													<figure className="image is-48x48">
														<img src={ServerIcon} alt="" />
													</figure>
												</div>
												<div className="media-content has-text-right">
													<h3 className="title is-1 has-text-success">
														{this.state.guilds.length}
													</h3>
												</div>
											</div>
											<h4 className="title">Serveurs</h4>
											<progress
												className="progress is-success"
												role="progressbar"
												value={this.state.guilds.length}
												max="100"
											></progress>
										</div>
									</div>
									<div className="column is-6-tablet is-3-desktop">
										<div className="box" onClick={this.goCommandsManager}>
											<div className="media">
												<div className="media-left">
													<figure className="image is-48x48">
														<img src={WaitIcon} alt="" />
													</figure>
												</div>
												<div className="media-content has-text-right">
													<h3 className="title is-1 has-text-info">
														{this.state.commands.length}
													</h3>
												</div>
											</div>
											<h4 className="title">Commandes</h4>
											<progress
												className="progress is-info"
												role="progressbar"
												value={this.state.commands.length}
												max="10000"
											></progress>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="section allsection">
							<div className="container py-4">
								<h2 className="title has-text-centered has-text-white mb-6">Panel's</h2>
								<div className="columns">
									<div className="column is-3">
										<h4 className="title is-spaced has-text-white is-4">Mail</h4>
										<p className="subtitle has-text-white is-6">Acceder au mails de Disc'Guard</p>
										<a
											href={() => false}
											className="button is-blue"
											onClick={this.goMail}
										>
											Suivant
										</a>
									</div>
									<div className="column is-3">
										<h4 className="title has-text-white is-spaced is-4">Utilisateurs</h4>
										<p className="subtitle has-text-white is-6">
											Acceder au panel des utilisateurs
										</p>
										<a
											href={() => false}
											className="button is-blue"
											onClick={this.goUsers}
										>
											Suivant
										</a>
									</div>
									<div className="column is-3">
										<h4 className="title has-text-white is-spaced is-4">Serveurs</h4>
										<p className="subtitle has-text-white is-6">
											Acceder au panel des serveurs
										</p>
										<a
											href={() => false}
											className="button is-blue"
											onClick={this.goServersManager}
										>
											Suivant
										</a>
									</div>
									<div className="column is-3">
										<h4 className="title has-text-white is-spaced is-4">Erreur</h4>
										<p className="subtitle has-text-white is-6">
											Acceder au panel des Erreurs
										</p>
										<a
											href={() => false}
											className="button is-blue"
											onClick={this.goErrorsManager}
										>
											Suivant
										</a>
									</div>
								</div>
							</div>
						</section>
					</>
				) : (
					<section className="section allsection">
						<div className="container has-text-centered">
							<div className="columns is-centered">
								<div className="column is-7">
									<h1 className="title has-text-white is-1">403</h1>
									<p className="subtitle has-text-white is-3">Accès interdit</p>
									<p>
										Woops! On dirait que vous cherchez quelque chose
										d'interdit...{' '}
									</p>
								</div>
							</div>
							<button
								className="button is-blue"
								onClick={this.goHome.bind()}
							>
								Retour à la maison
							</button>
						</div>
					</section>
				)}
			</>
		);
	}
}
export default Manager;
