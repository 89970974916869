import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { API_DOMAIN } from './config';
import Axios from 'axios';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Home from './components/pages/Home';
import Home2 from './components/pages/Home2';
import Login from './components/pages/Login';
import Logout from './components/pages/Logout';
import Contact from './components/pages/Contact';
import Privacy from './components/pages/Privacy';
import Terms from './components/pages/Terms';
import UserContext from './context/UserContext';
import PostList from './components/posts/PostList';
import Post from './components/posts/Post';
import NewPost from './components/posts/NewPost';
import Apropos from './components/pages/Apropos';
import Page404 from './components/pages/404page';
import CommingSoonPage from './components/pages/CommingSoonPage';
import Admin from './components/admin/Manager';
import ADMUserList from './components/admin/users/UserList';
import ADMServerList from './components/admin/servers/ServerList';
import ADMErrorList from './components/admin/errors/ErrorList';
import ServersList from './components/servers/ServerList'
import DashboardList from './components/dashboard/DashboardList'

import './main.css';

export default function App() {
	const [userData, setUserData] = useState({
		token: undefined,
		user: undefined
	});

	useEffect(() => {
		const checkLoggedIn = async () => {
			let token = localStorage.getItem('auth-token');
			if (token === null) {
				localStorage.setItem('auth-token', '');
				token = '';
			}
			const tokenRes = await Axios.post(
				`${API_DOMAIN}api/auth/tokenIsValid`,
				null,
				{ headers: { 'auth-token': token } }
			);
			if (tokenRes.data.status) {
				const userRes = await Axios.get(`${API_DOMAIN}api/auth/`, {
					headers: { 'auth-token': token }
				});
				setUserData({
					token,
					user: userRes.data
				});
			}
		};
		checkLoggedIn();
	}, []);

	return (
		<>
			<BrowserRouter>
				<UserContext.Provider value={{ userData, setUserData }}>
					<Header />
					<body className="landingpage">
						<div className="container">
							<Switch>
								<Route exact path="/" component={Home} />{' '}
							</Switch>{' '}
						</div>{' '}
					</body>
					<body className="section1">
						<div className="container">
							<Switch>
								<Route exact path="/" component={Home2} />{' '}
							</Switch>{' '}
						</div>{' '}
					</body>
					<body className="section2 ">
						<div className="container ">
							<Switch>
								<Route path="/apropos" component={Apropos} />{' '}
								<Route path="/login" component={Login} />{' '}
								<Route path="/logout" component={Logout} />{' '}
								<Route path="/contact" component={Contact} />{' '}
								<Route path="/privacy" component={Privacy} />{' '}
								<Route path="/terms" component={Terms} />{' '}
								<Route path="/admin" />{' '}
								<Route exact path="/posts/:id" />
								<Route exact path="/newpost" />
								<Route exact path="/posts" />
								<Route exact path="/menu" component={ServersList}/>
								<Route exact path="/dashboard/:id" component={DashboardList}/>
								<Route exact path="/" />
								<Route path="/" component={Page404} />{' '}
							</Switch>{' '}
						</div>{' '}
					</body>
					<body className="section3">
						<div className="container">
							<Switch>
								<Route exact path="/admin/users" component={ADMUserList} />{' '}
								<Route exact path="/admin/commands" component={CommingSoonPage} />{' '}
								<Route exact path="/admin/servers" component={ADMServerList} />{' '}
								<Route exact path="/admin/errors" component={ADMErrorList} />{' '}
								<Route exact path="/admin" component={Admin} />{' '}
								<Route exact path="/posts/:id" component={Post} />
								<Route exact path="/newpost" component={NewPost} />
								<Route exact path="/posts" component={PostList} />
								<Route exact path="/" /> <Route path="/" />{' '}
							</Switch>{' '}
						</div>{' '}
					</body>
					<Footer />
				</UserContext.Provider>{' '}
			</BrowserRouter>{' '}
		</>
	);
}
