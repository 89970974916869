import React from 'react';

class ErrorListItem extends React.Component {
	constructor(props) {
		super(props);

		this.onShowUser = this.onShowUser.bind(this);
	}
	onShowUser() {
		window.location.pathname = `/admin/users/${this.props.user._id}`;
	}

	renderDate(dateString) {
		const date = new Date(dateString);

		const monthNames = [
			'Janvier',
			'Fevrier',
			'Mars',
			'Avril',
			'Mai',
			'Juin',
			'Juillet',
			'Août',
			'Septembre',
			'Octobre',
			'Novembre',
			'Decembre'
		];

		return `${date.getDate()} ${
			monthNames[date.getMonth()]
		} ${date.getFullYear()}`;
	}

	render() {
        const { errors } = this.props;
		return (
			<div className="panel-block is-block py-3 px-4">
				<a className="userlevel" href={() => false}>
				{/* <a className="userlevel" onClick={this.onShowUser} href={() => false}> */}
					<div className="userlevel-left">
						<div className="userlevel-item has-text-white"><strong>Error Command: </strong>{errors.commandName}</div>
						<div className="userlevel-item has-text-white"><strong>Error Type: </strong>{errors.error.type}</div>
						<div className="userlevel-item has-text-white"><strong>Error Date: </strong>{this.renderDate(errors.date)}</div>
					</div>
					<div className="userlevel-right">
						<div className="userlevel-item">
							<button className="button is-blue" disabled>Voir plus</button>
							{/* <button className="button is-darkred" onClick={this.onShowUser}>Voir plus</button> */}
						</div>
					</div>
				</a>
			</div>
		);
	}
}

export default ErrorListItem;
