import React from 'react';

export default function Contact() {

	return (
		<section className="section is-large allsection">
			<div className="container has-text-centered">
				<div className="columns is-centered">
					<div className="column is-7">
						<h1 className="title is-1 has-text-white">Contact Us</h1>
						<p className="subtitle is-3 has-text-white">You can contact us in several ways:</p>
                        <ul>
                            <li>Email: contact@discguard.xyz</li>
                            <li>Discord: https://discord.gg/gTNGB2U7Q6</li>
                        </ul>
					</div>
				</div>
			</div>
		</section>
	);
}
