import React from 'react';
import Axios from 'axios';
import PostListItem from './PostListItem';
import CreatePost from './CreatePost';
import { API_DOMAIN } from '../../config';

class PostList extends React.Component {
	state = {
		posts: []
	};

	renderList() {
		this.state.posts.sort(function(a,b){
			// Turn your strings into dates, and then subtract them
			// to get a value that is either negative, positive, or zero.
			return new Date(b.createdAt) - new Date(a.createdAt);
		  });
		return this.state.posts.map((post, i) => {
			return <PostListItem post={post} key={post._id} />;
		});
	}
	componentDidMount() {
		this.getPosts();
	}

	async getPosts() {
		const res = await Axios.get(`${API_DOMAIN}api/posts/`);
		this.setState({ posts: res.data });
	}

	render() {
		return (
			<section className="section allsection">
				{/* <div className="box"> */}
				<nav className="breadcrumb" aria-label="breadcrumbs">
					<ul>
						<li className="is-active">
							<a href={() => false} aria-current="page ">
								Annoncements
							</a>
						</li>
					</ul>
				</nav>
				{/* </div> */}

				<div className="container py-4">
					<div className="postsbox">
						<h2 className="title has-text-centered has-text-white mb-6">
							Latest announcements
						</h2>

						<div className="columns is-multiline">{this.renderList()}</div>
						<CreatePost />
					</div>
					<nav>
						<div
							className="pagination is-centered"
							role="navigation"
							aria-label="pagination"
						>
							<a className="pagination-previous" href={() => false} disabled>
								Previous
							</a>
							<a className="pagination-next" href={() => false} disabled>
								Next
							</a>
							<ul className="pagination-list">
								<li>
									<a
										className="pagination-link is-current"
										aria-label="Page 1"
										aria-current="page"
										href={() => false}
									>
										1
									</a>
								</li>
								{/* <li><a className="pagination-link" aria-label="Goto page 2">2</a></li>
        <li><a className="pagination-link" aria-label="Goto page 3">3</a></li>
        <li><span className="pagination-ellipsis">…</span></li>
        <li><a className="pagination-link" aria-label="Goto page 47">5</a></li>
        <li><span className="pagination-ellipsis">…</span></li>
        <li><a className="pagination-link" aria-label="Goto page 86">10</a></li> */}
							</ul>
						</div>
					</nav>
				</div>
			</section>
		);
	}
}

export default PostList;
