import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import { getUserDetails, logoutUserDetails } from '../utils/api';

export default function AuthOptions() {
	const { userData, setUserData } = useContext(UserContext);

	const history = useHistory();

	function logout() {
		setUserData({
			token: undefined,
			user: undefined
		});
		localStorage.setItem('auth-token', '');
		logoutUserDetails();
		window.location.href = 'https://discguard.xyz/';
		// window.location.href = 'http://localhost:3000/';
	}
	const login = () =>
		(window.location.href =
			'https://api.discguard.xyz/api/auth/discord');
			// 'http://localhost:4000/api/auth/discord');

	React.useEffect(() => {
		getUserDetails().then(({ data }) => {
			localStorage.setItem('auth-token', data.dashboardToken);
			return setUserData(data);
		});
	}, []);

	return (
        <>
			{userData.discordId ? (
				<button className="button is-blue" onClick={logout}>
					Logout
				</button>
			) : (
				<button className="button is-blue" onClick={login}>
					Login
				</button>
			)}
		</>
	);
}
