import React from 'react';
import discordServerLogo from '../../assets/discordServer.png';

export default function ServerListItem({ guilds }) {
	const dashboard = (guildId) =>
		(window.location.href = `https://discguard.xyz/dashboard/${guildId}`);
		// (window.location.href = `http://localhost:3000/dashboard/${guildId}`);
	const invite = () =>
		(window.location.href = 'https://cutt.ly/DiscGuard');
	const owner = (ownerVar) => {
		if (ownerVar) {
			return 'Oui';
		} else {
			return 'Non';
		}
	};
	const icon = (guildId, guildIcon) => {
		if (guildIcon == null) {
			return discordServerLogo;
		} else {
			return `https://cdn.discordapp.com/icons/${guildId}/${guildIcon}.png`;
		}
	};

	return (
		<div className="servercards">
			{guilds.included.map((guild) => (
				<div className="cardblock">
					<div className="servercard has-text-centered">
						<div className="servercard-image is-centered">
							<div className="image is-128x128 ">
								<a href={`/dashboard/${guild.id}`}>
									<img
										className="is-rounded"
										src={icon(guild.id, guild.icon)}
										alt=""
									/>
								</a>
							</div>
						</div>
						<div className="servercard-content">
							<div className="block">
								<small className="is-size-7 has-text-white">
									Owner | {owner(guild.owner)}
								</small>
							</div>
							<h5 className="title is-6">
								<a onClick={dashboard.bind(null, guild.id)}>{guild.name}</a>
							</h5>
							<button
								className="button is-blue"
								onClick={dashboard.bind(null, guild.id)}
							>
								View Dashboard
							</button>
						</div>
					</div>
				</div>
			))}
			{guilds.excluded.map((guild) => (
				<div className="cardblock">
					<div className="servercard has-text-centered">
						<div className="servercard-image is-centered">
							<div className="image is-128x128 ">
								<a href={`/dashboard/${guild.id}`}>
									<img
										className="is-rounded"
										src={icon(guild.id, guild.icon)}
										alt=""
									/>
								</a>
							</div>
						</div>
						<div className="servercard-content">
							<div className="block">
								<small className="is-size-7 has-text-white">
									Owner | {owner(guild.owner)}
								</small>
							</div>
							<h5 className="title is-6">
								<a onClick={invite}>{guild.name}</a>
							</h5>
							<button className="button is-blue" onClick={invite}>
								Invite Bot
							</button>
						</div>
					</div>
				</div>
				// <div className="column is-6-tablet is-3-widescreen">
				// <div className="card">
				// <div className="card-header"><div className="card-header-title is-centered image is-128x128"><a href={`/dashboard/${guild.id}`}><img className =" is-rounded" src={icon(guild.id, guild.icon)} alt=""/></a></div></div>
				// <div className="card-content has-text-centered">
				//   <div className="block"><small className="is-size-7">Owner | {owner(guild.owner)}</small></div>
				//   <h5 className="title is-6"><a href={`https://cutt.ly/raidsecurity-bot`}>{guild.name}</a></h5>
				//   <button className="button"  href={`https://cutt.ly/raidsecurity-bot`}>Invite Bot</button>
				// </div>
				//     </div>
				//     </div>
			))}
		</div>
	);
}
